<template>
	<!-- 解决方案-销售机会-->
	<div>
		<div class="container">
			<GModule style="margin-bottom: 10px;">
				<ul class="money">
					<li>
						<p>上月新增</p>
						<p>{{addData.lm}}</p>
					</li>
					<li>
						<p>本月新增</p>
						<p>{{addData.m}}</p>
					</li>
					<li>
						<p>本季度新增</p>
						<p>{{addData.q}}</p>
					</li>
					<li>
						<p>本年新增</p>
						<p>{{addData.y}}</p>
					</li>
				</ul>
				<div class="condition-box">
					<div style="display: flex;">
						<div class="state-box">
							<p style="width: 30px;">客户</p>
							<a-input placeholder="请输入客户名称" style="width: 150px;" v-model:value="nickname"/>
						</div>
						<div class="state-box">
							<p style="width: 60px;">客户标签</p>
							<a-dropdown>
							    <template #overlay>
							        <a-menu selectable @click="handleMenuClick">
										<a-menu-item v-for="(nitem,nindex) in nstatusArr" :key="nindex">{{nitem.name}}</a-menu-item>
							        </a-menu>
							    </template>
							    <a-button>
									{{nstatusArr[nstatusIndex].name}}
							        <DownOutlined />
							    </a-button>
							</a-dropdown>
						</div>
						<div class="state-box">
							<p style="width: 60px;">创建时间</p>
							<a-range-picker
							    style="width: 400px"
							    :ranges="ranges"
							    show-time
							    format="YYYY-MM-DD HH:mm:ss"
								@change="change"/>
						</div>
					</div>
					<a-button class="btn clo3" @click="search()">查询</a-button>
					<a-button class="btn clo1" @click="openCustomer()"><plus-outlined />添加客户</a-button>
				</div>
				<a-table
					class="ttable" 
					:columns="columnsHe" 
					:data-source="data" 
					:scroll="{ x: 500}" 
					bordered
					:pagination="false">
					<template #bodyCell="{ column ,record,index}">
						<template v-if="column.dataIndex === 'labels_arr'">
							<span v-for="(itm,ind) in record.labels_arr" :key="ind">{{itm.name}}&nbsp;</span>
						</template>
						<template v-if="column.key === 'operation'">
							<div class="caozuo">
								<p @click="openSigning(record.id,record.name)" v-if="record.status == 'no_signed'">签约</p>
								<p @click="updateBtn(record.id,record.status)">编辑</p>
								<a-popconfirm
									v-if="record.status != 'signed'"
								    title="确认是否删除?"
								    ok-text="确认"
								    cancel-text="取消"
								    @confirm="delBtn(record.id,index)">
								    <p>删除</p>
								  </a-popconfirm>
							</div>
						</template>
					</template>
				</a-table>
				<div class="documentFt" style="margin-top: 10px;">
				  <a-pagination size="small" :pageSize="limit" :total="total" :current="page" @change="pageChange"/>
				</div>
			</GModule>		
		</div>
		
	</div>
</template>

<script>
	import dayjs from 'dayjs';
	export default{
		data(){
			return{
				nstatusArr:[
					{id: 0, name: "全部"},
				] , // 客户标签
				nstatusIndex:0, // 客户标签下标
				columnsHe: [{
				    title: '序号',
				    width: 40,
				    dataIndex: 'id',
				    fixed: 'left'
				  }, {
				    title: '项目名称',
				    width: 100,
				    dataIndex: 'name'
				  }, {
				    title: '重要程度',
				    width: 120,
				    dataIndex: 'level_text'
				  }, {
				    title: '客户名称',
				    width: 120,
				    dataIndex: 'nickname'
				  }, {
				    title: '客户标签',
				    width: 120,
				    dataIndex: 'labels_arr'
				  }, {
				    title: '联系人',
				    width: 100,
				    dataIndex: 'linkman'
				  }, {
				    title: '联系方式',
				    width: 120,
				    dataIndex: 'contact'
				  }, {
				    title: '创建时间',
				    width: 120,
				    dataIndex: 'createtime_text'
				  }, {
				    title: '状态',
				    width: 120,
				    dataIndex: 'status_text'
				  }, {
				    title: '备注',
				    width: 120,
				    dataIndex: 'remark'
				  },{
					title: '操作',
					key: 'operation',
					fixed: 'right',
					width: 100,
				}],
				data: [],
				limit:10,
				page:1,
				nickname:'' ,// 客户名称
				createtime:'' ,// 创建时间
				ranges: {
					'今天': [dayjs().startOf('day'), dayjs().endOf('day')],
					'本周': [dayjs().startOf('week'), dayjs().endOf('week')],
					'本月': [dayjs().startOf('month'), dayjs().endOf('month')],
					'一个月前': [dayjs().subtract(1, 'month'),dayjs().endOf('day')],
				},
				total:0 ,// 总条数
				addData:{} // 新增数
			}
		},
		async created() {
			this.getDetail()
		},
		computed:{
			signId(){
				return this.$store.state.signId
			},
			isFresh(){
				return this.$store.state.isFresh
			},
			updateId(){
				return this.$store.state.updateId
			},
			isUpdateFresh(){
				return this.$store.state.isUpdateFresh
			},
			proId(){
				return this.$store.state.proId  
			},
		},
		watch:{
			proId(val,oldvalue){
				if(val){
					// 重置数据，刷新列表
					this.page = 1 
					this.nickname = ''
					this.createtime = ''
					this.nstatusIndex = 0
					this.getDetail()
				}
			},
			signId(val,oldvalue){
				// 更改为已签约
				for(let i in this.data){
					if(this.data[i].id == val){
						this.data[i].status = "signed"
						this.data[i].status_text = "已签约"
						break
					}
				}
			},
			isFresh(val,oldvalue){
				// 添加后刷新数据
				if(val){
					// 重置数据，刷新列表
					this.page = 1 
					this.nickname = ''
					this.createtime = ''
					this.nstatusIndex = 0
					this.$store.commit('setIsFresh',0)
					this.getList()
				}
			},
			isUpdateFresh(val,oldvalue){
				// 修改完成后回显列表
				if(val){
					this.getInfo()
				}
			}
		},
		methods:{
			async getDetail(){
				// 获取客户标签
				let res = await this.$utils.api.get({
					url:'/sale/label_selectpage',
					result:1
				})
				if(res.code == 1){
					this.nstatusArr = this.nstatusArr.concat(res.data.list) // 拼接
					this.getList()
				}
			},
			handleMenuClick(e){
				// 选择可标签
				this.nstatusIndex = e.key
			},
			openCustomer(){
				// 弹出添加客户
				 this.$store.commit('setIsCustomerAdd',1)
				 this.$store.commit('setUpdateStatus',null) // 修改Id
				 this.$store.commit('setUpdateId',null) // 修改Id
			},
			change(date,value){
				// 选择创建时间
				if(value[0] == '' || value[1] == ''){
					// 创建时间要同时满足开始时间和结束时间才可以查询
					this.createtime = ''
					return
				}
				let val = value.join(' - ') // 拼接日期
				this.createtime = val
			},
			pageChange(page,pageSize){
				// 切换页码时查询列表
				this.page = page
				this.getList()
			},
			search(){
				// 根据条件查询列表
				this.page = 1 //每次点击查询按钮都要重置页码为1
				this.getList()
			},
		    async getList(){
				// 请求客户列表
				let info = {
					limit:this.limit,
					page:this.page,
					label_id:this.nstatusArr[this.nstatusIndex].id,
					op:{},
					filter:{}
				}
				if(this.nickname){
					info.op['nickname'] = 'LIKE'
					info.filter['nickname'] = this.nickname
				}
				if(this.createtime){
					info.op['createtime'] = 'RANGE'
					info.filter['createtime'] = this.createtime
				}
				let res = await this.$utils.api.post({
					url:'/sale/index',
					data:info
				})
				this.data = res.rows
				this.total = res.total
				this.addData = res.extend
			},
			openSigning(id,name){
				// 签约
				let info = {
					signingId:id, // 销售机会id
					signingName:name // 销售机会项目名称
					
				}
				this.$store.commit('setSignInfo',info)
				this.$store.commit('setIsSigning',1)
			},
			async delBtn(id,index){
				// 删除
				let res = await this.$utils.api.post({
					url:'/sale/del',
					data:{
						ids:id
					},
					result:1
				})
				if(res.code == 1){
					this.$utils.msgSuc('删除成功')
					this.data.splice(index,1)
				}
			},
			updateBtn(id,status){
				// 编辑
				this.$store.commit('setIsCustomerAdd',1) //弹出修改
				this.$store.commit('setUpdateStatus',status) // 签约状态
				this.$store.commit('setUpdateId',id) // 修改Id
			},
			async getInfo(){
				// 编辑成功后回显列表信息
				let res = await this.$utils.api.post({
					url:'/sale/row',
					data:{
						ids:this.$store.state.updateId
					},
					result:1
				})
				if(res.code == 1){
					for(let i in this.data){
						if(this.data[i].id == this.$store.state.updateId){
							this.data[i].nickname = res.data.nickname
							this.data[i].linkman = res.data.linkman
							this.data[i].contact = res.data.contact
							this.data[i].remark = res.data.remark
							this.data[i].level = res.data.level
							this.data[i].level_text = res.data.level == 1 ? '一级' : res.data.level == 2 ? '二级' : '三级'
							this.data[i].labels_arr = res.data.labels_arr
							break
						}
					}
					// 重置列表编辑id和标识
					this.$store.commit('setUpdateId',null)
					this.$store.commit('setIsUpdateFresh',0)
					this.$store.commit('setUpdateStatus',null) 
				}
			},
		}
	}
</script>

<style scoped lang="scss">
	.container{
		margin: 20px;
		overflow-x: hidden;
		.state-box{
			display: flex;
			justify-content: flex-start;
			align-items: center;
			margin-bottom: 5px;
		}
		.time-box{
			display: flex;
			align-items: center;
			p{
				&:nth-child(1){
					margin-right: 15px;
				}
				&:nth-child(2){
					width: 80px;
					font-size: 12px;
					color: #999999;
					border: 1px solid #ddd;
					border-radius: 5px;
					padding: 5px 10px;
				}
			}
		}
		.money{
			list-style: none;
			margin: 0;
			padding: 0;
			display: flex;
			background-color: #f5f5f5;
			margin-bottom: 15px;
			li{
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				flex: 1;
				padding: 20px 0;
				p{
					margin: 0;
					padding: 0;
					&:nth-child(1){
						margin-bottom: 10px;
					}
				}
			}
		}
		.condition-box{
			display: flex;
			flex-wrap: wrap;
			margin: 5px 0 10px 0;
			.state-box{
				display: flex;
				justify-content: flex-start;
				align-items: center;
				margin-bottom: 5px;
				p{
					margin: 0 10px 0 20px;
				}
			}
			.btn{
				margin-left: 20px;
				margin-bottom: 5px;
			}
		}
		.caozuo{
			display: flex;
			justify-content: space-around;
			align-items: center;
			p{
				margin: 0;
				font-size: 12px;
				color: #407cff;
			}
		}
	}
</style>